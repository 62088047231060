<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 설비유형 -->
          <c-equip-class
            label="LBL0001720"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 관리부서 -->
          <c-dept label="LBL0001721" name="managementDepts" v-model="searchParam.managementDepts" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 점검유형 -->
          <c-select
            codeGroupCd="EQUIP_INSPECTION_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipInspectionTypeCd"
            label="LBL0000350"
            v-model="searchParam.equipInspectionTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 단위공정 -->
          <c-process
            :editable="editable"
            label="LBL0001701"
            name="subProcessCd"
            v-model="searchParam.subProcessCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 폐기여부 -->
          <c-select
            :editable="editable"
            :comboItems="discardFlagItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="discardFlag"
            label="LBL0001723"
            v-model="searchParam.discardFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 법정설비여부 -->
          <c-select
            :editable="editable"
            :comboItems="flagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="lawEquipmentFlag"
            label="LBL0001724"
            v-model="searchParam.lawEquipmentFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 설비명 -->
          <c-text
            label="LBL0001725"
            name="equipmentName"
            v-model="searchParam.equipmentName">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <!-- 설비 목록 -->
    <c-table
      ref="equipTable"
      title="LBL0001726"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="equipmentCd"
      :columnSetting="false"
      :isExcelDown="false"
      :isFullScreen="false"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equip-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          equipmentTypeCd: '',
          grade: '',
          hazardousFlag: 'N',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      discardFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 설비관리번호
            label: 'LBL0001727',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0001725',
            align: 'left',
            sortable: true,
            type: 'link',
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0001720',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   label: '단위공정',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'managementDeptName',
          //   field: 'managementDeptName',
          //   label: '관리부서',
          //   align: 'center',
          //   sortable: true,
          // },
        ],
        data: [],
        height: '450px'
      },
      searchParam: {
        plantCd: null,
        equipmentTypeCd: '',
        equipmentName: '',
        equipInspectionTypeCd: null,
        managementDepts: '',
        subProcessCd: '',
        hazardousFlag: '',
        discardFlag: 'N',
        lawEquipmentFlag: null,
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.list.url;
      // code setting
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd
        this.searchParam.grade = this.popupParam.grade
      }
      if (this.popupParam.subProcessCd) {
        this.searchParam.subProcessCd = this.popupParam.subProcessCd
      }
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      if (this.popupParam.hazardousFlag) {
        this.searchParam.hazardousFlag = this.popupParam.hazardousFlag
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0001728'; // 설비 상세
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        psmFlag: row ? row.psmFlag : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
